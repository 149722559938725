<template>
    <div class="SBei2">
        <div style="font-size: 14px; margin-top: 10px;">IMEI:{{ deviceName}}</div>

        <div class="btn-box1">
            <van-button @click="shebeiBtnClick({type:5})" class="btn1" plain type="info" size="small">全量查询</van-button>
            <van-button @click="shebeiBtnClick({type:9})" class="btn1" plain type="info" size="small">复位</van-button>
            <van-button @click="shebeiBtnClick({type:4})" class="btn1" plain type="info" size="small">激活</van-button>
            <van-button @click="shebeiBtnClick({type:3,v:3})" class="btn1" plain type="info" size="small">主控升级</van-button>
            <van-button @click="shebeiBtnClick({type:3,v:2})" class="btn1" plain type="info" size="small">从控升级</van-button>
            <van-button @click="shebeiBtnClick({type:7})" class="btn1" plain type="info" size="small">调度指令</van-button>
        </div>


     
    </div>
  </template>
  <script>
  import { Notify } from "vant";
  export default {
    data() {
      return {
        deviceName: localStorage.getItem("deviceName"),
        requestEnable:true,

      };
    },
    created() {
        if(this.deviceName){
            this.shebeiBtnClick({type:5},true)
        }
     
    },
    destroyed() {
     
    },
    methods: {
        shebeiBtnClick(e,isCreate){
            if (!this.requestEnable){
                return
            }
            this.requestEnable = false
            setTimeout(res=>{
                this.requestEnable = true
            },1000)
            var params = {...e}
            params.deviceName = this.deviceName
            this.$http.developmenttest(params).then(res=>{
                if (isCreate){
                    if (res.code != 200){
                        Notify({ type: "primary", message: res.msg });
                    }
                    return
                }
                Notify({ type: "primary", message: res.msg });
            })

        }
    
    },
  };
  </script>
  <style lang="scss" scoped>
  .SBei2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-box1{
        width:355px;
        .btn1{
            margin-top: 20px;
            margin-left: 5px;
            margin-right: 5px;
        }
        
    }
  
  }
  </style>